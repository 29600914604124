import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Container, Row, Col, Card } from "react-bootstrap";
// import parse from "html-react-parser";
// import { graphql, Link } from "gatsby";

const HowToUse = ({ data }) => {
  // const newData = data.allWpPost.nodes;
  // const tutorials = newData.filter((item) => {
  //   const nodes = item.categories.nodes;
  //   for (let i = 0; i < nodes.length; i++) {
  //     if (nodes[i].name === "Tutorial") {
  //       return item;
  //     }
  //   }
  // });

  return (
    <Layout>
      <Helmet>
        <title>Learn how to use Hello-AR app</title>
        <meta
          name="description"
          content="Double your auto sales with a truly immersive catalog in under 10 minutes"
        />
        <meta
          name="keywords"
          content="360 for auto, 360 for bikes, Ricoh Theta for cars, HelloAR, extended reality, enterprise platform, Walkaround 360, Remove background, contactless sales, cars 360, 360 spin, IOT platform, 360 for cars"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>
      <div className="container-fluid">
        <div className="spacer"></div>
        <div className="spacer d-sm-none"></div>
        <div className="spacer d-xl-none"></div>
        <h1 className="h1 text-center">Learn how to use HelloAR app</h1>
        <div className="spacer-sm"></div>
        {/* <div className="blogsContainer">
          <Container>
            <Row className="align-items-stretch">
              {tutorials.map((blog, index) => (
                <Col key={index} md={4}>
                  <Card className="border-0 shadow-none">
                    <Card.Body className="bg-light shadow-none">
                      <Card.Title className="font-weight-bold text-dark">
                        {parse(blog.title)}
                      </Card.Title>
                      <Card.Text className="text-dark">
                        {parse(blog.excerpt)}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="border-0 bg-light shadow-none">
                      <Link to={blog.uri} itemProp="url">
                        <a href="#" className="d-flex m-0">
                          <h6>
                            Read more{" "}
                            <img
                              className="ml-2"
                              src="../icons/ic_small_next_arrow.svg"
                              alt="Right arrow"
                            />
                          </h6>
                        </a>
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="spacer"></div>
          </Container>
        </div> */}
      </div>
    </Layout>
  );
};

export default HowToUse;

// export const pageQuery = graphql`
//   query TutorialArchive($tutorialsCategoryId: String!) {
//     allWpPost(
//       sort: { fields: [date], order: DESC }
//       filter: {
//         categories: {
//           nodes: { elemMatch: { id: { eq: $tutorialsCategoryId } } }
//         }
//       }
//     ) {
//       nodes {
//         excerpt
//         uri
//         date(formatString: "MMMM DD, YYYY")
//         title
//         categories {
//           nodes {
//             name
//           }
//         }
//         featuredImage {
//           node {
//             altText
//             localFile {
//               childImageSharp {
//                 fluid(maxWidth: 1000, quality: 100) {
//                   ...GatsbyImageSharpFluid_tracedSVG
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
